import styles from "./Project.module.css";

const Project = () => {
  return (
    <div className={styles.project}>
      <div className={styles.image}>
        <img src="placeholder.jpg" alt="Project Image" />
      </div>
      <div className={styles.content}>
        <h2 className={styles.title}>Project Title</h2>
        <p className={styles.date}>January 1, 2023</p>
        <p className={styles.description}>
          This is a brief description of the project, summarizing its purpose,
          features, and goals.
        </p>
        <div className={styles.stack}>
          <strong>Stack:</strong>
          <span> HTML</span>
          <span> CSS</span>
          <span> JavaScript</span>
          <span> React</span>
        </div>
        <div className={styles.link}>
          <a href="#" target="_blank" rel="noreferrer">
            gitHub
          </a>
        </div>
      </div>
    </div>
  );
};
export default Project;
