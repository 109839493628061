import styles from "./Projects.module.css";
import SectionHeader from "../../sectionHeader/SectionHeader";
import Project from "./project/Project";

const Projects = () => {
  return (
    <section className={styles}>
      <SectionHeader text="Projects" />
      <div className={styles.projects}>
        <Project />
        <Project />
        <Project />
        <Project />
      </div>
    </section>
  );
};
export default Projects;
