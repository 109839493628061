import styles from "./Main.module.css";
import Intro from "./intro/Intro";
import Skills from "./skills/Skills";
import Projects from "./projects/Projects";

const Main = () => {
  return (
    <div className={styles.main}>
      <Intro />
      <Skills />
      <Projects />
    </div>
  );
};
export default Main;
